@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes colorCycle {
  0%, 100% { color: red; }
  33% { color: yellow; }
  67% { color: green; }
}

.color-cycle {
  animation: colorCycle 3s infinite;
}

.background-logo {
  z-index: 1; /* Lower z-index for the background */
}

.foreground-logo {
  z-index: 2; /* Higher z-index for the foreground */
}

.how-we-work-section {
  scroll-margin-top: 80px;  /* Adjust this value based on the height of your sticky header */
}

.service-bespoke-section{
  scroll-margin-top: 200px;
}

.portfolio-section{
  scroll-margin-top: 55px;
}

